import { Container, Row, Col } from 'react-bootstrap';

export default function About() {
  return (
    <Container>
      <Row>
        <Col>
          <h1>About Mixonance...</h1>
        </Col>
      </Row>
    </Container>
  );
}