import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {Container, Navbar, Nav, Row, Col, Button} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';

import About from './components/About';
import './App.css';

function App() {
  return (
    <Router>
    <div className="App">
      <Container fluid>
        <Navbar bg="light" expand="lg">
          <Navbar.Brand href="/">Mixonance</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/about">About</Nav.Link>
              <Nav.Link href="/projects">Projects</Nav.Link>
            </Nav>
            <Nav className="justify-content-end">
              <LinkContainer to="/login">
                <Button href="/login">Login</Button>
              </LinkContainer>
              
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <main>
          <Container>
            <Row>
              <Col>Seven of Nine</Col>
            </Row>
          </Container>
          <Switch>
            <Route path="/about">
              <About />
            </Route>
          </Switch>
        </main>
      </Container>
    </div>
    </Router>
  );
}

export default App;
